import React, { useState } from 'react';
import { Box, Grid2, useMediaQuery, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CodeInput from '../components/CodeInput';
import FloatingCards from '../components/FloatingCards';
import { useAppContext } from '../AppContext';
import { useNavigate } from 'react-router-dom';
import './ClaimCard.css';
import { API_BASE_URL } from '../constants';
import CardRedeemed from '../components/CardRedeemed';
import CloseIcon from '@mui/icons-material/Close';
import Footer from '../components/Footer';

const ClaimCard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isCodeComplete, setIsCodeComplete] = useState(false);
  const [redeemedCards, setRedeemedCards] = useState({});
  const [allCollections, setAllCollections] = useState([]);
  const [redeemedCode, setRedeemedCode] = useState('');

  const cards = require('../assets/cards_gradient_1.png');
  const wicked_logo = require('../assets/wicked_logo_small.png');

  const { userId, pasteCodeResult, setPasteCodeResult } = useAppContext();

  const handleCodeComplete = (code) => {
    if (code.length == 6) {
      setIsCodeComplete(true);
      setRedeemedCode(code);
    }
  }

  const handleClaimCard = async (code) => {
    if (!code) return;
    console.log(code);
    const userId = window.nbc  && window.nbc.identity && window.nbc.identity.profile
      ? window.nbc.identity.profile._id : "";
    if (!userId) {
      navigate('/wicked/');
    }
    // First create the user link record if needed
    try {
      const linkResult = await fetch(`${API_BASE_URL}/users/link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': window.nbc.identity.token
        },
        body: JSON.stringify({ user_id: userId }) // Convert the data to a JSON string
      });
      if (!linkResult.ok) {
        const linkMsg = await linkResult.json();
        console.log("ERROR: >>>", linkMsg.errorMsg);
      }  
    }
    catch(e) {
      console.log(e);
    }

    // Then if the card is valid, redeem the card
    const redeemRequestBody = {
      // redeemed_by: '9285156d-254f-4735-970e-6979f3d1a3e6', //TODO: Make me real
      redeemed_by: userId
    };
    const result = await fetch(`${API_BASE_URL}/codes/${code}/redeem`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': window.nbc.identity.token
      },
      body: JSON.stringify(redeemRequestBody) // Convert the data to a JSON string
    });
    if (!result.ok) {
      const msg = await result.json();
      console.log("ERROR: >>>", msg.errorMsg);
      setErrorMessage(msg.errorMsg);
      return;
    }
    const json = await result.json();
    console.log('redeemed', json);

    setIsClaimed(true);
    await fetchCollectionImage();
    await getRedeemedCodes();
  };

  const getRedeemedCodes = async () => {
    // const userId = "9285156d-254f-4735-970e-6979f3d1a3e6";
    const url = `${API_BASE_URL}/codes/${redeemedCode}`;
    const result = await fetch(url, { headers: { 'accept': 'application/json' } });
    const json = await result.json();
    console.log("get redeemed codes >>>", json);
    const redeemed = {};
    if (!result.ok) {
      console.log("error redeeming code", pasteCodeResult);
      return;
    }
    if (json.reward == "ALL_CARDS") {
      navigate('/wicked/collection');
      return;
    }

    json.mint_id.split(",").map((c) => {
      const collectionId = c.split(":")[0];
      redeemed[collectionId] = 1;
    });
    console.log(redeemed);
    setRedeemedCards(redeemed);
  };

  const fetchCollectionImage = async () => {
    const getCardLink = `${API_BASE_URL}/collection`;
    const result = await fetch(getCardLink, { headers: { 'accept': 'application/json' } });
    const json = await result.json();
    console.log("get all collections >>>", json);
    setAllCollections(json);
  };

  return (
    <div>
      <Box className="claim-page">
        <img src={wicked_logo} alt="Wicked logo" className="claim-header-image" />

        <button onClick={() => navigate('/wicked/')} style={styles.closeButton}>
          <CloseIcon/>
        </button>
        {!isSmallScreen && !isClaimed && <FloatingCards/>}
        {isClaimed ? (
          <div className="modalClaimed">
            <CardRedeemed allCollections={allCollections} redeemedCards={redeemedCards} />
          </div>
        ) : (
          <Box className={isSmallScreen ? "modal modal-sm" : "modal"}>
            {isSmallScreen && <FloatingCards/>}
            <Box style={{ width: '350px', margin: '0 auto', display: 'block' }}>
              <p style={{ color: '#fff', fontWeight: 300, fontSize: '1rem' }}>
                Enter your code
              </p>
              <p style={{ color: '#fff', fontWeight: 300, fontSize: '1.2rem' }}>
                Claim your free digital card!
              </p>
              <CodeInput length={6} onComplete={handleCodeComplete} onSubmit={handleClaimCard} />

              {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage} </p>}
            </Box>
          </Box>)
        }
      </Box>
      <Footer />
    </div>
  );
};

const styles = {
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '20px',
    padding: '23px',
    backgroundColor: 'black',
    border: '2px solid #14a905',
    opacity: 0.7,
    borderRadius: '300px',
    width: '26px',
    height: '26px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default ClaimCard;